import React from 'react';
import SvgIcon from './SvgIcon';

function ArrowBackIcon({ width }) {
  return (
    <SvgIcon className="ArrowBackIcon" width={width}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        fill="none"
        viewBox="0 0 24 24"
      >
        <g clipPath="url(#clip0_808_2303)">
          <path
            fill="#fff"
            d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20z"
          />
        </g>
        <defs>
          <clipPath id="clip0_808_2303">
            <path fill="#fff" d="M0 0h24v24H0z" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}

export default ArrowBackIcon;
