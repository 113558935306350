import React from 'react';
import './ContactUs.css';
import ThribeeFavicon from 'pages/Homepage/images/ThribeeFavicon';
import MitulaLogo from 'pages/Homepage/images/MitulaLogo';
import NestoriaLogo from 'pages/Homepage/images/NestoriaLogo';
import NuroaLogo from 'pages/Homepage/images/NuroaLogo';
import TrovitLogo from 'pages/Homepage/images/TrovitLogo';
import { dispatch } from 'reffects';
import { subscribe } from 'reffects-store';
import ArrowBackIcon from 'components/library/Icons/ArrowBackIcon';
import showFormSuccessSelector from 'pages/Homepage/Main/ContactUs/selectors';
import registerEvents from './events';

registerEvents();

function ContactUs({
  onFormSubmit,
  onContactFormUpdate,
  onGoBack,
  showFormSuccess,
}) {
  return (
    <section className="section section--form">
      <div className="inner section--content">
        <div className="boxIntro">
          <div className="intro">
            <h1
              dangerouslySetInnerHTML={{
                __html: trovitApp.i18nBIS('homepage_section1_title'),
              }}
            />
            <div className="list">
              <div className="isotip">
                <ThribeeFavicon />
              </div>
              <div>{trovitApp.i18nBIS('homepage_section1_subtitle1')}</div>
            </div>
            <div className="list">
              <div className="isotip">
                <ThribeeFavicon />
              </div>
              <div>{trovitApp.i18nBIS('homepage_section1_subtitle2')}</div>
            </div>
            <div className="boxLogos">
              <TrovitLogo />
              <MitulaLogo />
              <NestoriaLogo />
              <NuroaLogo />
            </div>
          </div>
          {showFormSuccess ? (
            <FormSuccess onGoBack={onGoBack} />
          ) : (
            <Form
              onFormSubmit={onFormSubmit}
              onContactFormUpdate={onContactFormUpdate}
            />
          )}
        </div>
      </div>
    </section>
  );
}

function Form({ onFormSubmit, onContactFormUpdate }) {
  return (
    <div className="form">
      <h3>{trovitApp.i18nBIS('homepage_form_title')}</h3>
      <p
        className="form--desc"
        dangerouslySetInnerHTML={{
          __html: trovitApp.i18nBIS('homepage_form_subtitle'),
        }}
      />
      <form
        id="contact"
        name="contact"
        method="post"
        className="formContact"
        onSubmit={onFormSubmit}
      >
        <div className="rowForm">
          <label htmlFor="email" className="label">
            {trovitApp.i18nBIS('homepage_form_email')} *
          </label>
          <input
            type="email"
            id="email"
            name="email"
            className="input"
            required
            onChange={onContactFormUpdate}
          />
        </div>
        <div className="rowForm">
          <label htmlFor="country" className="label">
            {trovitApp.i18nBIS('homepage_form_country')} *
          </label>
          <input
            type="text"
            id="country"
            name="country"
            className="input"
            required
            onChange={onContactFormUpdate}
          />
        </div>
        <div className="rowForm">
          <label htmlFor="company" className="label">
            {trovitApp.i18nBIS('homepage_form_company')} *
          </label>
          <input
            type="text"
            id="company"
            name="company"
            className="input"
            required
            onChange={onContactFormUpdate}
          />
        </div>
        <div className="rowForm">
          <label htmlFor="name" className="label">
            {trovitApp.i18nBIS('homepage_form_name')} *
          </label>
          <input
            type="text"
            id="name"
            name="name"
            className="input"
            required
            onChange={onContactFormUpdate}
          />
        </div>
        <div className="rowForm">
          <label htmlFor="surname" className="label">
            {trovitApp.i18nBIS('homepage_form_surname')} *
          </label>
          <input
            type="text"
            id="surname"
            name="surname"
            className="input"
            required
            onChange={onContactFormUpdate}
          />
        </div>
        <div className="rowForm checkboxForm">
          <label>
            <input
              type="checkbox"
              name="checkbox"
              id="checkbox_policy"
              required
              onChange={onContactFormUpdate}
            />
            <span>
              {trovitApp.i18nBIS('homepage_form_terms_and_conditions', {
                termsAndConditionsUrl: `/index.php/cod.public-terms-and-conditions`,
                privacyPolicyUrl: `/index.php/cod.public-terms-and-conditions?anchor=AnchorToPrivacyPolicy`,
              })}
            </span>
          </label>
        </div>
        <div className="rowButton">
          <button className="btn" id="btn_form" type="submit">
            {trovitApp.i18nBIS('homepage_button_demorequest')}
          </button>
        </div>
      </form>
    </div>
  );
}

function FormSuccess({ onGoBack }) {
  return (
    <div className="form formSuccess">
      <button
        onClick={onGoBack}
        type="button"
        aria-label="back"
        className="arrowBackButton"
      >
        <ArrowBackIcon />
      </button>
      <div className="partyPopperEmoji">&#127881;</div>
      <span className="formSuccess__title">
        {trovitApp.i18nBIS('homepage_form_success_title')}
      </span>
      <span className="formSuccess__description">
        {trovitApp.i18nBIS('homepage_form_success_subtitle')}
      </span>
      <button
        onClick={onGoBack}
        className="btn formSuccess__button"
        id="btn_form"
        type="button"
      >
        {trovitApp.i18nBIS('homepage_form_success_button')}
      </button>
    </div>
  );
}
export default subscribe(
  ContactUs,
  function mapStateToProps(state) {
    return {
      showFormSuccess: showFormSuccessSelector(state),
    };
  },
  {
    onFormSubmit(e) {
      e.preventDefault();
      dispatch('SUBMIT_CONTACT_FORM');
    },
    onGoBack() {
      dispatch('GO_BACK');
    },
    onContactFormUpdate(e) {
      dispatch({
        id: 'UPDATE_CONTACT_FORM',
        payload: {
          [e.target.name]: e.target.value,
        },
      });
    },
  }
);
