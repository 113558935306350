import { registerEventHandler } from 'reffects';
import { state } from 'reffects-store';
import { http } from 'reffects-batteries';

const registerEvents = () => {
  registerEventHandler(
    'SUBMIT_CONTACT_FORM',
    function ({ state: { contactFormData } }) {
      const contactFormPostUrl = '/index.php/contact-form';
      return {
        ...http.post({
          body: contactFormData,
          url: contactFormPostUrl,
          successEvent: ['SUBMIT_CONTACT_FORM_SUCCESS'],
          errorEvent: ['SUBMIT_CONTACT_FORM_ERROR'],
        }),
      };
    },
    [
      state.get({
        contactFormData: 'contactFormData',
      }),
    ]
  );

  registerEventHandler(
    'UPDATE_CONTACT_FORM',
    function updateCampaignEditFormData(
      { state: { contactFormData } },
      newFormData
    ) {
      return state.set({
        contactFormData: { ...contactFormData, ...newFormData },
      });
    },
    [
      state.get({
        contactFormData: 'contactFormData',
      }),
    ]
  );

  registerEventHandler(
    'SUBMIT_CONTACT_FORM_SUCCESS',
    () => ({
      ...state.set({
        showSubmitSuccess: true,
      }),
    }),
    []
  );

  registerEventHandler(
    'SUBMIT_CONTACT_FORM_ERROR',
    () => ({
      ...state.set({
        showSubmitSuccess: false,
      }),
    }),
    []
  );

  registerEventHandler(
    'GO_BACK',
    () => ({
      ...state.set({
        showSubmitSuccess: false,
      }),
    }),
    []
  );
};

export default registerEvents;
